


































































































    import { CampusStore } from "@/app/store/campus";
import { SessionStore } from "@/app/store/session";
    import { Campus } from "@/domain/entity";
    import { Component, Prop, Vue } from "vue-property-decorator";
    import { getModule } from "vuex-module-decorators";

    @Component({
        components: {}
    })
    export default class CampusFormView extends Vue {
        progress = false
        @Prop({required: false, default: '', type: String}) id_campus: string
        campus = new Campus('', '', '', '', 0.0, 0.0, '', true, '', '')
        titleButton = "Crear sede"
        showModalIntitution = false
        institutions = ['WIENER', 'CARRION']

        get campusStore(): CampusStore {
            return getModule(CampusStore, this.$store)
        }

        get sessionStore(): SessionStore {
            return getModule(SessionStore, this.$store)
        }

        async onSubmit() {
            if (!this.validateName()) {
                this.loadError('El nombre supera la cantidad límite de carácteres')
                return
            }

            if (!this.validateAddress()) {
                this.loadError('La dirección supera la cantidad límite de carácteres')
                return
            }

            if (!this.validateLatitude()) {
                this.loadError('Latitud supera la cantidad límite de carácteres')
                return
            }

            if (!this.validateLongitude()) {
                this.loadError('Longitud supera la cantidad límite de carácteres')
                return
            }

            if (!this.validateInstitution()) {
                this.loadError('Institución no es correcta')
                return
            }

            this.campusStore.callSave(this.campus)
                .then(success => {
                    this.$router.push("/campus");
                    this.$toast.open({
                        message: `Se ${this.id_campus ? 'modificó' : 'registró'} correctamente`,
                        type: 'success',
                        position: 'bottom-right'
                    })
                })
                .catch(exception => {
                    this.sessionStore.actionValidateException(exception)
                })
                .finally(() => {
                    this.progress = false
                })
        }

        mounted() {
            if (this.id_campus) {
                this.titleButton = "Guardar sede"
                this.campusStore.callById(this.id_campus)
                    .then(campus => {
                        this.campus = campus
                    })
                    .catch(exception => {
                        this.sessionStore.actionValidateException(exception)
                    })
                    .finally(() => {
                        this.progress = false
                    })
            }
        }

        validateName(): boolean {
            if (this.campus.name.length > 36) {
                return false
            } else {
                return true
            }
        }

        validateAddress(): boolean {
            if (this.campus.address.length > 90) {
                return false
            } else {
                return true
            }
        }

        validateLatitude(): boolean {
            if (String(this.campus.latitude).length > 20) {
                return false
            } else {
                return true
            }
        }

        validateLongitude(): boolean {
            if (String(this.campus.longitude).length > 20) {
                return false
            } else {
                return true
            }
        }

        validateInstitution(): boolean {
            if (this.campus.institution != 'WIENER' && this.campus.institution != 'CARRION') {
                return false
            } else {
                return true
            }
        }

        cancel() {
            this.$router.push("/campus")
        }

        loadError(message: string) {
            this.$toast.open({
                message: message,
                type: 'error',
                position: 'bottom-right'
            })
        }

    }
