










































































import {Component, Vue} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import {SessionStore} from "@/app/store/session";

@Component
export default class AuthView extends Vue {
  get sessionStore(): SessionStore {
    return getModule(SessionStore, this.$store)
  }

  loading = false
  model = {
    email: '',
    password: ''
  }

  async onSubmit() {
    this.loading = true
    this.sessionStore.callSignIn({email: this.model.email, password: this.model.password})
      .then(success => {
        console.log('onSubmit [success]: ', success)
        this.loading = false
        //this.$router.push(success ? "/profile" : "/home")
        this.$router.push("/news")
        this.$toast.open({
          message: `Acceso correcto${success ? ". Tu password es autogenerada, por favor cámbiala" : ""}`,
          type: 'success',
          position: 'bottom-right'
        });
      })
      .catch(exception => {
        this.loading = false
        this.sessionStore.actionValidateException(exception)
      })
  }

  created() {
    this.sessionStore.callGetSession()
      .then(session => {
        if(session.token) {
          this.sessionStore.setSession(session)
          this.$router.push("/news");
        }
      })
  }

}
