























































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn} from "element-ui";
import { Campus, News } from "@/domain/entity";
import { SessionStore } from "@/app/store/session";
import { getModule } from "vuex-module-decorators";
import { CampusStore } from "@/app/store/campus";

@Component({
components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu
}
})
export default class CampusListView extends Vue {
    campus: Campus[] = []
    total: number = 1
    perPage: number = 10
    emptyText: string = "No se encontrarón resultados."
    progress = false
    model = {
        currentPage: 1,
        text: ''
    }
    @Prop({required: false, default: 'default', type: String}) type: string = 'default'

    get sessionStore(): SessionStore {
        return getModule(SessionStore, this.$store)
    }

    get campusStore(): CampusStore {
        return getModule(CampusStore, this.$store)
    }

    mounted() {
        this.findPagination()
    }

    findPagination() {
        this.progress = true
        this.campusStore.callPagination({text: this.model.text, page: this.model.currentPage})
        .then(pagination => {
            let campusList : Campus[] = []
            if (pagination.list != null) {
                for (let item of pagination.list) {
                    item.name = (item.name.length > 20) ? (item.name.substring(0, 20)) + '...' : item.name
                    item.address = (item.address.length > 20) ? (item.address.substring(0, 20)) + '...' : item.address
                    campusList.push(item)
                }
            }
            
            this.campus = campusList
            this.perPage = pagination.per_page
            this.total = pagination.total
            this.campusStore.setPagination(pagination)
        })
        .catch(exception => {
            console.log('exception => ', exception);
            
            this.sessionStore.actionValidateException(exception)
        })
        .finally(() => {
            this.progress = false
        })
    }

    formatter(row: Campus): string {
        return row.active ? "Activo" : "Inactivo"
    }

    formatterPractice(row: Campus): string {
        return row.practice ? "Si" : "No"
    }

    loadEdit(id_campus: string) {
        this.$router.push(`/campus/${id_campus}/edit`);
    }

    loadAdd() {
        this.$router.push(`/campus/add`);
    }

    deleteCar(id_campus: string) {
        this.progress = true
        this.campusStore.callDeleteById(id_campus)
        .then(success => {
            this.findPagination()
            this.$toast.open({
            message: 'Se eliminó correctamente',
            type: 'success',
            position: 'bottom-right'
            });
        })
        .catch(exception => {
            this.sessionStore.actionValidateException(exception)
        })
        .finally(() => {
            this.progress = false
        })
    }

    changeEvent(event: Event) {
        this.model.currentPage = Number(event)
        this.findPagination()
    }

}

