



















import {Vue, Component} from "vue-property-decorator";

import NewsFormView from "@/app/views/news/NewsFormView.vue"

@Component ({
    components: { NewsFormView }
})
export default class NewsAddView extends Vue {

}
