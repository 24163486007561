



















import {Vue, Component} from "vue-property-decorator";
import FeatureFormView from "@/app/views/feature/FeatureFormView.vue"

@Component ({
    components: { FeatureFormView }
})
export default class FeatureAddView extends Vue {

}
