



















import {Vue, Component} from "vue-property-decorator";
import FeatureFormView from "@/app/views/feature/FeatureFormView.vue"

@Component ({
    components: { FeatureFormView }
})
export default class FeatureEditView extends Vue {
    id_feature: string = ""

    created() {
        this.id_feature = this.$route.params.id_feature
    }
}
