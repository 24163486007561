










































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { NewsStore } from "@/app/store/news";
import { SessionStore } from "@/app/store/session";
import { News } from "@/domain/entity";
import { FirebaseStorageStore } from "@/app/store/firebaseStorage";
import { DateTime } from "luxon";
import { DatetimePopup } from 'vue-datetime';

interface ImageProperties {
    blank: boolean
    blankColor: string
    width: number
    height: number
    class: string
    url: string
}

interface EnumProperties {
    key: string
    value: string
}

@Component({
    components: {DatetimePopup}
})
export default class NewsFormView extends Vue {
    
    @Prop({required: false, default: '', type: String}) id_news: string
    mainProps1: ImageProperties = {blank: true, blankColor: '#88a8b8', width: 160, height: 175, class: 'm1', url: ''}
    mainProps2: ImageProperties = {blank: true, blankColor: '#88a8b8', width: 160, height: 175, class: 'm1', url: ''}
    mainProps3: ImageProperties = {blank: true, blankColor: '#88a8b8', width: 160, height: 175, class: 'm1', url: ''}
    mainProps4: ImageProperties = {blank: true, blankColor: '#88a8b8', width: 160, height: 175, class: 'm1', url: ''}
    mainProps5: ImageProperties = {blank: true, blankColor: '#88a8b8', width: 160, height: 175, class: 'm1', url: ''}
    date: DateTime = new DateTime.utc().setZone('local').set({seconds: 0, milliseconds: 0})
    hours: DateTime = new DateTime.utc().setZone('local').set({seconds: 0, milliseconds: 0})
    titleButton = "Crear noticia"

    showModalType = false
    showModalAction = false
    showModalIntitution = false
    showDate = false
    showHours = false
    hideHours = false
    progress = false
    news = new News('', '', '', '', '', '', '', '', '', ['', '', '', '', ''], true, '', '')
    typeValue: EnumProperties = {
        key: '',
        value: ''
    }
    actionValue: EnumProperties = {
        key: '',
        value: ''
    }
    types: EnumProperties[] = [
        {
            key: 'EVENT',
            value: 'Evento'
        },
        {
            key: 'SEMINARY',
            value: 'Seminario'
        },
        {
            key: 'WORKSHOP',
            value: 'Taller'
        },
        {
            key: 'NEWS_INTEREST',
            value: 'Noticias de interés'
        },
        {
            key: 'WELFARE',
            value: 'Bienestar'
        }
    ]
    actions: EnumProperties[] = [
        {
            key: 'EMPTY',
            value: 'Vacio'
        },
        {
            key: 'CALENDAR',
            value: 'Calendario'
        },
        {
            key: 'URL',
            value: 'Url'
        }
    ]
    institutions = ['WIENER', 'CARRION']

    get newsStore(): NewsStore {
        return getModule(NewsStore, this.$store)
    }

    get firebaseStorageStore(): FirebaseStorageStore {
        return getModule(FirebaseStorageStore, this.$store)
    }

    get sessionStore(): SessionStore {
        return getModule(SessionStore, this.$store)
    }

    async onSubmit() {
        if (!this.validateTitle()) {
            this.loadError('El título supera la cantidad límite de carácteres')
            return
        }

        if (!this.validateDescription()) {
            this.loadError('La descripción supera la cantidad límite de carácteres')
            return
        }

        if (!this.validateInstitution()) {
            this.loadError('Institución no es correcta')
            return
        }

        if (!this.validateType()) {
            this.loadError('Tipo no es correcto')
            return
        }

        if (!this.validatePhotos()) {
            this.loadError('Sube las fotos en el orden propuesto')
            return
        }

        if (!this.validateUrl()) {
            this.loadError('Ingresa una url válida')
            return
        }

        this.progress = true
        if (this.actionValue.key === "EMPTY" || this.actionValue.key === "CALENDAR") {
            this.news.action = this.actionValue.key
        }
        this.newsStore.callSave(this.news)
        .then(success => {
            this.$router.push("/news");
            this.$toast.open({
            message: `Se ${this.id_news ? 'modificó' : 'registró'} correctamente`,
            type: 'success',
            position: 'bottom-right'
            })
        })
        .catch(exception => {
            this.sessionStore.actionValidateException(exception)
        })
        .finally(() => {
            this.progress = false
        })
    }

    openFile1() {
        document.getElementById('fileInput1').click()
    }

    openFile2() {
        document.getElementById('fileInput2').click()
    }

    openFile3() {
        document.getElementById('fileInput3').click()
    }

    openFile4() {
        document.getElementById('fileInput4').click()
    }

    openFile5() {
        document.getElementById('fileInput5').click()
    }

    previewFiles1(event: Event) {
        this.sendFiles(event, 0, this.mainProps1)
    }

    previewFiles2(event: Event) {
        this.sendFiles(event, 1, this.mainProps2)
    }

    previewFiles3(event: Event) {
        this.sendFiles(event, 2, this.mainProps3)
    }

    previewFiles4(event: Event) {
        this.sendFiles(event, 3, this.mainProps4)
    }

    previewFiles5(event: Event) {
        this.sendFiles(event, 4, this.mainProps5)
    }

    mounted() {
        if (this.id_news) {
            this.titleButton = "Guardar noticia"
            this.newsStore.callById(this.id_news)
                .then(news => {
                    this.news = news
                    for (let item of this.types) {
                        if (item.key == news.type) {
                            this.typeValue = item
                            break
                        }
                    }

                    for (let item of this.actions) {
                        if (item.key == news.action) {
                            this.actionValue = item
                            break
                        }
                    }

                    this.mainProps1.url = (news.url_photos.length > 0) ? news.url_photos[0] : ''
                    this.mainProps2.url = (news.url_photos.length > 1) ? news.url_photos[1] : ''
                    this.mainProps3.url = (news.url_photos.length > 2) ? news.url_photos[2] : ''
                    this.mainProps4.url = (news.url_photos.length > 3) ? news.url_photos[3] : ''
                    this.mainProps5.url = (news.url_photos.length > 4) ? news.url_photos[4] : ''
                })
                .catch(exception => {
                    this.sessionStore.actionValidateException(exception)
                })
                .finally(() => {
                    this.progress = false
                })
        }
    }

    sendFiles(event: Event, position: number, propertie: ImageProperties) {
        if (event.target.files.length > 0) {
            this.progress = true
            this.firebaseStorageStore.callUploadImage({file: event.target.files[0], path: 'news'})
                .then(url => {
                    propertie.blank = false
                    propertie.url = url
                    this.news.url_photos[position] = url
                })
                .catch(exception => {
                    this.sessionStore.actionValidateException(exception)
                })
                .finally(() => {
                    this.progress = false
                })
        }
    }

    confirmDate(datetime: DateTime) {
        this.date = datetime
        this.showDate = false
        this.news.date = datetime.toFormat('yyyy-MM-dd')
    }

    confirmHours(datetime: DateTime) {
        this.hours = datetime
        this.showHours = false
        this.news.hours = datetime.toFormat('HH:mm:ss')
    }

    loadError(message: string) {
        this.$toast.open({
        message: message,
        type: 'error',
        position: 'bottom-right'
        })
    }

    validateUrl(): boolean {
        if (this.actionValue.key == 'URL' && this.news.action == '') {
            return false
        } else {
            return true
        }
    }

    validateTitle(): boolean {
        if (this.news.title.length > 43) {
            return false
        } else {
            return true
        }
    }

    validateDescription(): boolean {
        if (this.news.description.length > 250) {
            return false
        } else {
            return true
        }
    }

    validatePhotos(): boolean {
        if (this.news.url_photos[0] == '') {
            return false
        }

        let flag = false
        if (this.news.url_photos[0] != '') {
            flag = true
        }

        if (flag && this.news.url_photos[1] == '' && this.news.url_photos[2] != '') {
            flag = false
        } else {
            flag = true
        }
        
        if (flag && this.news.url_photos[2] == '' && this.news.url_photos[3] != '') {
            flag = false
        } else {
            flag = true
        }

        if (flag && this.news.url_photos[3] == '' && this.news.url_photos[4] != '') {
            flag = false
        } else {
            flag = true
        }

        return flag
    }

    validateInstitution(): boolean {
        if (this.news.institution != 'WIENER' && this.news.institution != 'CARRION') {
            return false
        } else {
            return true
        }
    }

    validateType(): boolean {
        if (this.news.type != 'EVENT' && 
            this.news.type != 'SEMINARY' &&
            this.news.type != 'WORKSHOP' &&
            this.news.type != 'NEWS_INTEREST' &&
            this.news.type != 'WELFARE') {
            return false
        } else {
            return true
        }
    }

    cancel() {
        this.$router.push("/news")
    }

}
