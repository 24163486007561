











































































import { FeatureStore } from "@/app/store/feature";
import { SessionStore } from "@/app/store/session";
import { Feature } from "@/domain/entity";
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

@Component({
    components: {}
})
export default class FeatureFormView extends Vue {
    progress = false
    @Prop({required: false, default: '', type: String}) id_feature: string
    feature = new Feature('', '', '', '', '', true, '', '')
    titleButton = "Crear funcionalidad"
    showModalIntitution = false
    institutions = ['WIENER', 'CARRION']

    get featureStore(): FeatureStore {
        return getModule(FeatureStore, this.$store)
    }

    get sessionStore(): SessionStore {
        return getModule(SessionStore, this.$store)
    }

    async onSubmit() {
        if (!this.validateName()) {
            this.loadError('El nombre supera la cantidad límite de carácteres')
            return
        }

        if (!this.validateDescription()) {
            this.loadError('La descripción supera la cantidad límite de carácteres')
            return
        }

        if (!this.validateInstitution()) {
            this.loadError('Institución no es correcta')
            return
        }

        this.featureStore.callSave(this.feature)
            .then(success => {
                this.$router.push("/feature");
                this.$toast.open({
                    message: `Se ${this.id_feature ? 'modificó' : 'registró'} correctamente`,
                    type: 'success',
                    position: 'bottom-right'
                })
            })
            .catch(exception => {
                this.sessionStore.actionValidateException(exception)
            })
            .finally(() => {
                this.progress = false
            })
    }

    mounted() {
        if (this.id_feature) {
            this.titleButton = "Guardar funcionalidad"
            this.featureStore.callById(this.id_feature)
                .then(feature => {
                    this.feature = feature
                })
                .catch(exception => {
                    this.sessionStore.actionValidateException(exception)
                })
                .finally(() => {
                    this.progress = false
                })
        }
    }

    validateName(): boolean {
        if (this.feature.name.length > 36) {
            return false
        } else {
            return true
        }
    }

    validateDescription(): boolean {
        if (this.feature.description.length > 90) {
            return false
        } else {
            return true
        }
    }

    validateInstitution(): boolean {
        if (this.feature.institution != 'WIENER' && this.feature.institution != 'CARRION') {
            return false
        } else {
            return true
        }
    }

    cancel() {
        this.$router.push("/feature")
    }

    loadError(message: string) {
        this.$toast.open({
            message: message,
            type: 'error',
            position: 'bottom-right'
        })
    }

}
