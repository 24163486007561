



















































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn} from "element-ui";
import { News } from "@/domain/entity";
import { SessionStore } from "@/app/store/session";
import { getModule } from "vuex-module-decorators";
import { NewsStore } from "@/app/store/news";

@Component({
components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu
}
})
export default class NewsListView extends Vue {
    news: News[] = []
    total: number = 1
    perPage: number = 10
    emptyText: string = "No se encontrarón resultados."
    progress = false
    model = {
        currentPage: 1,
        text: ''
    }
    @Prop({required: false, default: 'default', type: String}) type: string = 'default'

    get sessionStore(): SessionStore {
        return getModule(SessionStore, this.$store)
    }

    get newsStore(): NewsStore {
        return getModule(NewsStore, this.$store)
    }

    mounted() {
        this.findPagination()
    }

    findPagination() {
        this.progress = true
        this.newsStore.callPagination({text: this.model.text, page: this.model.currentPage})
        .then(pagination => {
            let newList : News[] = []
            if (pagination.list != null) {
                for (let item of pagination.list) {
                    item.title = (item.title.length > 20) ? (item.title.substring(0, 20)) + '...' : item.title
                    item.description = (item.description.length > 20) ? (item.description.substring(0, 20)) + '...' : item.description
                    newList.push(item)
                }
            }
            
            this.news = newList
            this.perPage = pagination.per_page
            this.total = pagination.total
            this.newsStore.setPagination(pagination)
        })
        .catch(exception => {
            console.log('exception => ', exception);
            
            this.sessionStore.actionValidateException(exception)
        })
        .finally(() => {
            this.progress = false
        })
    }

    formatter(row: News): string {
        return row.active ? "Activo" : "Inactivo"
    }

    loadEdit(id_news: string) {
        this.$router.push(`/news/${id_news}/edit`);
    }

    loadAdd() {
        this.$router.push(`/news/add`);
    }

    deleteCar(id_news: string) {
        this.progress = true
        this.newsStore.callDeleteById(id_news)
        .then(success => {
            this.findPagination()
            this.$toast.open({
            message: 'Se eliminó correctamente',
            type: 'success',
            position: 'bottom-right'
            });
        })
        .catch(exception => {
            this.sessionStore.actionValidateException(exception)
        })
        .finally(() => {
            this.progress = false
        })
    }

    changeEvent(event: Event) {
        this.model.currentPage = Number(event)
        this.findPagination()
    }

}

