



















import {Vue, Component} from "vue-property-decorator";

import CampusFormView from "@/app/views/campus/CampusFormView.vue"

@Component ({
    components: { CampusFormView }
})
export default class CampusAddView extends Vue {

}
