



















import {Vue, Component} from "vue-property-decorator";
import NewsFormView from "@/app/views/news/NewsFormView.vue"

@Component ({
    components: { NewsFormView }
})
export default class NewsEditView extends Vue {
    id_news: string = ""

    created() {
        this.id_news = this.$route.params.id_news
    }
}
