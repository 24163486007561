



















import {Vue, Component} from "vue-property-decorator";
import CampusFormView from "@/app/views/campus/CampusFormView.vue"

@Component ({
    components: { CampusFormView }
})
export default class CampusEditView extends Vue {
    id_campus: string = ""

    created() {
        this.id_campus = this.$route.params.id_campus
    }
}
